import styled from "styled-components";

const StyledFooterContainer = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  flex-wrap: wrap;
  text-align: left;
`;

const StyledFooterItem = styled.div`
  width: 25%;

  @media (max-width: 767px) {
    width: 100%;
    text-align: center;
  }

  .logo {
    width: 150px;
  }
`;

const StyledIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;

  @media (max-width: 767px) {
    justify-content: center;
    margin-bottom: 40px;
  }
`;

export { StyledFooterContainer, StyledFooterItem, StyledIconContainer };
