import { createGlobalStyle } from "styled-components";
import "typeface-roboto";

const GlobalStyles = createGlobalStyle`

  html, body {
      height: 100%;
      width: 100%;
  }

  body {
      font-family: 'Roboto', sans-serif;
      background-color:  ${(props) => props.theme.colors.whiteBg};
  }   

  a {
      text-decoration: none;
  }

  * {
      box-sizing: border-box;
      margin:0;
      padding:0;
  }

  .index {
    position: relative;
    z-index: 1;
  }

  .panel {
    position: absolute;
    left: -200px;
    top: 0px;
    height: 100%;
    z-index: 0;
  }

  .panel-2 {
    position: absolute;
    right: -150px;
    bottom: 0px;
    height: 70%;
    z-index: 0;
  }

  .panel-left {
    position: absolute;
    left: 0;
    top: -100px;
    height: 50%;
    z-index: 0;
  }

  .panel-right {
    position: absolute;
    right: 0;
    top: -400px;
    height: 50%;
    z-index: 0;
  }

  .panel-left-bottom {
    position: absolute;
    left: -100px;
    bottom: -200px;
    height: 50%;
    z-index: 0;
  }

  .panel-right-bottom {
    position: absolute;
    right: 0;
    bottom: -400px;
    height: 50%;
    z-index: 0;
  }

  .textLink {
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.colors.gray};

    svg {
      fill: ${(props) => props.theme.colors.gray};
      height: 30px;
      margin-right: 10px;
      transition: fill 0.3s ease-in-out;

      &:hover {
        fill: ${(props) => props.theme.colors.dark};
      }
    }
  }

  .Feed {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    padding-bottom: 30px;

    a {
      width: 25%;
      padding: 5px;

      @media (max-width: 767px) {
        width: 50%;
      }

      img {
        width: 100%;
      }
    }
  }

  .phone {
    display: none !important;
  }

  .telButton {
    display: flex !important;
  }

  .timesInfo {
    font-size: 12px;
    text-align: left;
    color: rgb(155, 129, 122);
    line-height: 18px;
    margin-top: 20px;
  }

  .powereBy {
    margin-top: 20px;
    width: 100%;
    border-top: 1px solid #9e847d;
    padding-top: 20px;

    a {
      color: #9B817A;
      margin-left: 4px;
    }

    p {
      font-size: 11px;
      line-height: 14px;
    }
  }

  @media (max-width: 768px) {
    .noPhone {
      display: none;
    }
  }

  @media (max-width: 767px) {
    .borders {
      padding-left: 20px;
      padding-right: 20px;
    }

    .bannerMobile {
      height: 325px;
    }

    .mobileContainer{
      padding-left: 0px;
      padding-right: 0px;
    }

    .phone {
      display: flex !important;
    }

    .telButton {
      display: none !important;
    }

    .timesInfo {
      text-align: center;
    }

    .powereBy {
      text-align: center;
    }
  }
`;

export default GlobalStyles;
