import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

const colorStyle = (props) => {
  if (props.bgColor === "transparent") {
    return css`
      background-color: transparent;
    `;
  }
  return css`
    background-color: ${props.theme.colors[props.bgColor]};
  `;
};

const StyledContainer = styled.div`
  position: relative;
  max-width: ${(props) => props.theme.layout.max_width};
  margin: 0 auto;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 40px;
  text-align: center;
  ${(props) => colorStyle(props)};
`;

const Container = ({ children, bgColor, ...rest }) => {
  return (
    <StyledContainer bgColor={bgColor} {...rest}>
      {children}
    </StyledContainer>
  );
};

Container.propTypes = {
  bgColor: PropTypes.oneOf([
    "light_pink",
    "dark",
    "gray",
    "white",
    "pink",
    "whiteBg",
    "transparent",
  ]),
};

Container.defaultProps = {
  bgColor: "light_pink",
};

export default Container;
