import styled from 'styled-components';

const StyledSliderContainer = styled.div`
  .slick-dots {
    bottom: 25px;
  }

  .slick-dots li.slick-active button:before {
    color: ${props => props.theme.colors.darkPink};
    opacity: 1;
  }

  .slick-dots li button:before {
    color: ${props => props.theme.colors.white};
    font-size: 12px;
    opacity: 1;
  }

  .slick-track {

    div {
      outline: none;
    }
  }
`;

const StyledSliderItem = styled.div`
  width:100%;
  position: relative;
  height: 325px;

  @media (min-width: 768px) {
    height: 450px;
  }

  @media (min-width: 1024px) {
    height: 550px;
  }

  @media (min-width: 1280px) {
    height: 650px;
  }
  
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    z-index: 1;
    transform: translate(-50%,-50%); 
    object-fit: cover;
  }
`;

export {
  StyledSliderContainer,
  StyledSliderItem,
};