import React from 'react';
import {
  Container,
} from '../../components';

const ColumnContainer = () => {
  return (
    <Container>
      
    </Container>
  );
};

ColumnContainer.propTypes = {};

ColumnContainer.defaultProps = {};

export default ColumnContainer;
