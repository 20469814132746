import styled from "styled-components";

const StyledMap = styled.a`
  width: 100%;
  height: 400px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  margin-top: 50px;
  margin-bottom: 50px;
`;

export { StyledMap };
