import styled from "styled-components";

const StyledColumnContainer = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 50px;
  margin-bottom: 50px;
  max-width: ${(props) => props.theme.layout.max_width};
  margin: 0 auto;
  width: 100%;
`;

const StyledServicesInfo = styled.div`
  padding-top: 40px;
  padding-bottom: 30px;
`;

const StyledColumn = styled.div`
  width: 45%;
  margin-bottom: 50px;

  @media (max-width: 767px) {
    width: 100%;
  }

  img {
    width: 100%;
    margin-bottom: 30px;
  }
`;

export { StyledColumnContainer, StyledServicesInfo, StyledColumn };
