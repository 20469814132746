import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import "intersection-observer";
import { useInView } from "react-intersection-observer";

import { Container, ImageSlider, Text, Title } from "../../components";
import team_1 from "../../assets/team/team_1.jpg";
import team_2 from "../../assets/team/team_2.jpg";
import team_3 from "../../assets/team/team_3.jpg";
import team_4 from "../../assets/team/team_4.jpg";
import team_5 from "../../assets/team/team_5.jpg";
import panel from "../../assets/sfondi/penneli-solo.png";

const sliderImages = [
  { img: team_1, alt: "team_1" },
  { img: team_2, alt: "team_2" },
  { img: team_3, alt: "team_3" },
  { img: team_4, alt: "team_4" },
  { img: team_5, alt: "team_5" },
];

const AboutUs = ({ sliderLoaded }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <Container
      bgColor="pink"
      className="mobileContainer"
      style={{
        marginTop: "180px",
        maxWidth: "100%",
        paddingBottom: "50px",
      }}
    >
      <motion.div
        ref={ref}
        animate={controls}
        initial={{ opacity: 0, y: 150 }}
        transition={{
          ease: "easeInOut",
          duration: 0.3,
          delay: 0.1,
          damping: 20,
        }}
        variants={{
          visible: { opacity: 1, y: 0 },
          hidden: { opacity: 0, y: 150 },
        }}
      >
        <ImageSlider
          images={sliderImages}
          bgColor="transparent"
          style={{
            marginTop: "-200px",
            marginBottom: "50px",
            paddingLeft: "0px",
            paddingRight: "0px",
            zIndex: "1",
          }}
          sliderLoaded={sliderLoaded}
        />
      </motion.div>
      <div
        style={{
          position: "absolute",
          background: "transparent",
          width: "100%",
          height: "100%",
          top: "0",
          zIndex: "0",
          left: "0",
          overflow: "hidden",
        }}
      >
        <img src={panel} alt="panel" className="panel noPhone" />
        <img src={panel} alt="panel" className="panel-2 noPhone" />
      </div>

      <div
        className="borders"
        style={{
          zIndex: "1",
          position: "relative",
        }}
      >
        <Text
          color="white"
          weight="light"
          style={{ marginBottom: "20px", letterSpacing: "2px" }}
        >
          CHI SIAMO
        </Text>
        <Title color="white">
          io sono claudia <br />e questa é la mia squadra
        </Title>
        <Text color="white">
          Nel 2018 ho aperto Charlotte da pasticciera autodidatta, insieme ai miei soci Diego, Dario e Marta.<br />
          A servirvi troverete il sorriso di mio fratello Alessio.<br />
          Nel nostro piccolo laboratorio, 4 mani: le mie e quelle di Policarpo Won.
        </Text>
      </div>
    </Container>
  );
};

AboutUs.propTypes = {};

AboutUs.defaultProps = {};

export default AboutUs;
