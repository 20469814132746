import styled from "styled-components";

const StyledHeaderContainer = styled.div`
  background-color: ${(props) => props.theme.colors.light_pink};
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 767px) {
    flex-wrap: wrap;
  }
`;

const StyledHeaderInfo = styled.div`
  display: flex;
  justify-content: start-right;
  font-size: 15px;

  @media (max-width: 767px) {
    justify-content: center;
    width: 100%;
  }

  div {
    margin-right: 30px;
    display: flex;
    align-items: center;

    @media (max-width: 767px) {
      margin-right: 0;
    }

    &:last-child {
      margin-right: 0px;
    }

    p {
      margin-right: 10px;
      color: ${(props) => props.theme.colors.gray};
    }
  }
`;

const StyledLogo = styled.div`
  @media (max-width: 767px) {
    width: 100%;
  }
  svg {
    width: 150px;
  }
`;

export { StyledHeaderContainer, StyledHeaderInfo, StyledLogo };
