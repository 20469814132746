import styled from 'styled-components';

const StyledButton = styled.div`
  background-color: ${props => props.theme.colors.dark};
  border-radius: 40px;
  font-size: 16px;
  color: ${props => props.theme.colors.light_pink};
  text-transform: uppercase;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 25px;
  padding-right: 25px;
  transition: background-color 0.3s ease-in-out;
  cursor: pointer;
  border: none;
  font-weight: 700;
  letter-spacing: 3px;
  font-family: GothamBold, sans-serif;
  outline: none;
  display: inline-block;

  @media (max-width: 767px) {
    font-size: 12px;
  }

  &:hover {
    background-color: ${props => props.theme.colors.gray};
  }
`;

export {
  StyledButton,
};

