import React from "react";
import { Container, Title, Text } from "../../components";
import { StyledMap } from "./StyledContact";

import mappa from "../../assets/MappaRosa.jpg";

const Contact = () => {
  return (
    <Container className="mobileContainer">
      <div className="borders">
        <Title>ti aspettiamo</Title>
        <Text>Siamo in Via Vercelli 12-14, Roma - Metro A - Re di Roma</Text>
      </div>
      <StyledMap
        href="https://www.google.com/maps/place/Via+Vercelli,+12,+00182+Roma+RM/@41.8816638,12.5142376,17z/data=!3m1!4b1!4m5!3m4!1s0x132f61edbb358933:0x520ca2d597a005ea!8m2!3d41.8816638!4d12.5164263"
        target="_blank"
        rel="noopener noreferrer"
        style={{ backgroundImage: `url(${mappa})` }}
      />
    </Container>
  );
};

Contact.propTypes = {};

Contact.defaultProps = {};

export default Contact;
