import React from "react";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import { StyledPresentation, StyledPresentationBg } from "./StyledPresentation";

const Presentation = ({ isLoaded }) => {
  return (
    <StyledPresentationBg isLoaded={isLoaded}>
      <StyledPresentation>
        <Logo />
        <div className="spinner">
          <div className="double-bounce1"></div>
          <div className="double-bounce2"></div>
        </div>
      </StyledPresentation>
    </StyledPresentationBg>
  );
};

Presentation.propTypes = {};

Presentation.defaultProps = {};

export default Presentation;
