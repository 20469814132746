import React from "react";
import { Container } from "../";
import {
  StyledHeaderContainer,
  StyledHeaderInfo,
  StyledLogo,
} from "./StyledHeader";
import { Button } from "../";
import { ReactComponent as Logo } from "../../assets/logo.svg";

const Header = () => {
  return (
    <Container style={{ paddingTop: "20px" }}>
      <StyledHeaderContainer>
        <StyledLogo>
          <Logo />
        </StyledLogo>
        <StyledHeaderInfo>
          <div>
            <p className="noPhone">Vieni a trovarci</p>
            <a
              href="https://www.google.com/maps/place/Via+Vercelli,+12,+00182+Roma+RM/data=!4m2!3m1!1s0x132f61edbb358933:0x520ca2d597a005ea?sa=X&ved=2ahUKEwie77D1vs_rAhVUPcAKHcR-CFUQ8gEwAHoECAsQAQ"
              target="_blank"
              rel="noopener noreferrer"
              className="textLink"
            >
              <svg
                viewBox="-119 -21 682 682.66669"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="m216.210938 0c-122.664063 0-222.460938 99.796875-222.460938 222.460938 0 154.175781 222.679688 417.539062 222.679688 417.539062s222.242187-270.945312 222.242187-417.539062c0-122.664063-99.792969-222.460938-222.460937-222.460938zm67.121093 287.597656c-18.507812 18.503906-42.8125 27.757813-67.121093 27.757813-24.304688 0-48.617188-9.253907-67.117188-27.757813-37.011719-37.007812-37.011719-97.226562 0-134.238281 17.921875-17.929687 41.761719-27.804687 67.117188-27.804687 25.355468 0 49.191406 9.878906 67.121093 27.804687 37.011719 37.011719 37.011719 97.230469 0 134.238281zm0 0" />
              </svg>
            </a>
          </div>
          <div>
            <p className="noPhone">Seguici sui social</p>
            <a
              href="https://www.facebook.com/Charlotte-Pasticceria-Roma-106341417580840"
              target="_blank"
              rel="noopener noreferrer"
              className="textLink"
            >
              <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                <path d="m512 256c0-141.4-114.6-256-256-256s-256 114.6-256 256 114.6 256 256 256c1.5 0 3 0 4.5-.1v-199.2h-55v-64.1h55v-47.2c0-54.7 33.4-84.5 82.2-84.5 23.4 0 43.5 1.7 49.3 2.5v57.2h-33.6c-26.5 0-31.7 12.6-31.7 31.1v40.8h63.5l-8.3 64.1h-55.2v189.5c107-30.7 185.3-129.2 185.3-246.1z" />
              </svg>
            </a>
            <a
              href="https://www.instagram.com/charlotte_pasticceria/?hl=it"
              target="_blank"
              rel="noopener noreferrer"
              className="textLink"
            >
              <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                <path d="m305 256c0 27.0625-21.9375 49-49 49s-49-21.9375-49-49 21.9375-49 49-49 49 21.9375 49 49zm0 0" />
                <path d="m370.59375 169.304688c-2.355469-6.382813-6.113281-12.160157-10.996094-16.902344-4.742187-4.882813-10.515625-8.640625-16.902344-10.996094-5.179687-2.011719-12.960937-4.40625-27.292968-5.058594-15.503906-.707031-20.152344-.859375-59.402344-.859375-39.253906 0-43.902344.148438-59.402344.855469-14.332031.65625-22.117187 3.050781-27.292968 5.0625-6.386719 2.355469-12.164063 6.113281-16.902344 10.996094-4.882813 4.742187-8.640625 10.515625-11 16.902344-2.011719 5.179687-4.40625 12.964843-5.058594 27.296874-.707031 15.5-.859375 20.148438-.859375 59.402344 0 39.25.152344 43.898438.859375 59.402344.652344 14.332031 3.046875 22.113281 5.058594 27.292969 2.359375 6.386719 6.113281 12.160156 10.996094 16.902343 4.742187 4.882813 10.515624 8.640626 16.902343 10.996094 5.179688 2.015625 12.964844 4.410156 27.296875 5.0625 15.5.707032 20.144532.855469 59.398438.855469 39.257812 0 43.90625-.148437 59.402344-.855469 14.332031-.652344 22.117187-3.046875 27.296874-5.0625 12.820313-4.945312 22.953126-15.078125 27.898438-27.898437 2.011719-5.179688 4.40625-12.960938 5.0625-27.292969.707031-15.503906.855469-20.152344.855469-59.402344 0-39.253906-.148438-43.902344-.855469-59.402344-.652344-14.332031-3.046875-22.117187-5.0625-27.296874zm-114.59375 162.179687c-41.691406 0-75.488281-33.792969-75.488281-75.484375s33.796875-75.484375 75.488281-75.484375c41.6875 0 75.484375 33.792969 75.484375 75.484375s-33.796875 75.484375-75.484375 75.484375zm78.46875-136.3125c-9.742188 0-17.640625-7.898437-17.640625-17.640625s7.898437-17.640625 17.640625-17.640625 17.640625 7.898437 17.640625 17.640625c-.003906 9.742188-7.898437 17.640625-17.640625 17.640625zm0 0" />
                <path d="m256 0c-141.363281 0-256 114.636719-256 256s114.636719 256 256 256 256-114.636719 256-256-114.636719-256-256-256zm146.113281 316.605469c-.710937 15.648437-3.199219 26.332031-6.832031 35.683593-7.636719 19.746094-23.246094 35.355469-42.992188 42.992188-9.347656 3.632812-20.035156 6.117188-35.679687 6.832031-15.675781.714844-20.683594.886719-60.605469.886719-39.925781 0-44.929687-.171875-60.609375-.886719-15.644531-.714843-26.332031-3.199219-35.679687-6.832031-9.8125-3.691406-18.695313-9.476562-26.039063-16.957031-7.476562-7.339844-13.261719-16.226563-16.953125-26.035157-3.632812-9.347656-6.121094-20.035156-6.832031-35.679687-.722656-15.679687-.890625-20.6875-.890625-60.609375s.167969-44.929688.886719-60.605469c.710937-15.648437 3.195312-26.332031 6.828125-35.683593 3.691406-9.808594 9.480468-18.695313 16.960937-26.035157 7.339844-7.480469 16.226563-13.265625 26.035157-16.957031 9.351562-3.632812 20.035156-6.117188 35.683593-6.832031 15.675781-.714844 20.683594-.886719 60.605469-.886719s44.929688.171875 60.605469.890625c15.648437.710937 26.332031 3.195313 35.683593 6.824219 9.808594 3.691406 18.695313 9.480468 26.039063 16.960937 7.476563 7.34375 13.265625 16.226563 16.953125 26.035157 3.636719 9.351562 6.121094 20.035156 6.835938 35.683593.714843 15.675781.882812 20.683594.882812 60.605469s-.167969 44.929688-.886719 60.605469zm0 0" />
              </svg>
            </a>
          </div>
          <div className="phone">
            <a
              href="tel:+39 0625399727"
              target="_blank"
              rel="noopener noreferrer"
              className="textLink"
            >
              <svg viewBox="0 0 34 34" version="1.1" xmlns="http://www.w3.org/2000/svg"> <defs> <polygon id="path-1" points="7.18367347 7.17948718 26.0408163 7.17948718 26.0408163 26.9230769 7.18367347 26.9230769"></polygon> </defs> <g id="Page-1" stroke="none" strokeWidth="1"> <g id="Mobile-Charlotte-375" transform="translate(-254.000000, -209.000000)"> <g id="Group-18" transform="translate(87.000000, 209.000000)"> <g id="Group-5"> <g id="Group-3"> <g id="tel" transform="translate(167.000000, 0.000000)"> <ellipse id="Oval" cx="16.6122449" cy="16.6025641" rx="16.6122449" ry="16.6025641"></ellipse> <mask> <use></use> </mask> <g id="Clip-2"></g> <path d="M13.0200482,15.6667216 C13.4909611,17.7332171 16.0338905,20.2916493 17.9175787,20.7836629 C18.7651851,20.9805067 19.7070108,20.4884547 19.9895953,19.6028302 C20.3663256,18.3235949 21.9674293,17.9299841 22.909255,18.9139728 L25.5463302,21.6692489 C26.205645,22.3581063 26.205645,23.5389389 25.5463302,24.3261607 L23.7568982,26.1958123 C21.8732467,28.1638667 16.8815705,25.9990069 12.4549896,21.3740407 C8.02837196,16.749113 6.05057475,11.533769 7.84004361,9.56575308 L9.62951247,7.69610145 C10.2887905,7.00728242 11.4189445,7.00728242 12.1724419,7.69610145 L14.8095539,10.4513391 C15.7513796,11.4354047 15.3746493,13.1082509 14.1502759,13.5018618 C13.302596,13.7970315 12.7375005,14.7810971 13.0200482,15.6667216" fill="#f8eff0"></path> </g> </g> </g> </g> </g> </g> </svg>
            </a>
          </div>
          <div className="telButton">
            <Button tag={"a"} href={"tel:+39 0625399727"}>
              +39 06 25399727
            </Button>
          </div>
        </StyledHeaderInfo>
      </StyledHeaderContainer>
    </Container>
  );
};

Header.propTypes = {};

Header.defaultProps = {};

export default Header;
