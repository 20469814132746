import React from "react";
import PropTypes from "prop-types";
import { StyledText } from "./StyledText";

const Text = ({ children, color, styles, weight, ...rest }) => {
  return (
    <StyledText color={color} styles={styles} weight={weight} {...rest}>
      {children}
    </StyledText>
  );
};

Text.propTypes = {
  color: PropTypes.oneOf(["light_pink", "dark", "gray", "white", "pink"]),
  styles: PropTypes.oneOf(["normal", "italic"]),
  weight: PropTypes.oneOf(["bold", "normal", "light"]),
};

Text.defaultProps = {
  color: "gray",
  styles: "normal",
  weight: "normal",
};

export default Text;
