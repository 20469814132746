import React from "react";
import { Container, Title, Text } from "../../components";
import {
  StyledBannersContainer,
  StyledBannersColumnsContainer,
  StyledBannersColumn,
} from "./StyledBanners";

import BannerItem from "./BannerItem";

import materiePrime from "../../assets/mix/materiePrime.jpg";
import prodotti from "../../assets/mix/prodotti.jpg";
import spazio from "../../assets/mix/spazio.jpg";

const Banners = () => {
  return (
    <Container className="mobileContainer">
      <StyledBannersContainer>
        <BannerItem image={{ src: materiePrime, alt: "materiePrime" }} />
        <div className="borders">
          <Title color="dark">LE NOSTRE MATERIE PRIME</Title>
          <Text color="dark">
            Dalla vaniglia del Madagascar al burro di Normandia, dal cioccolato
            Valrhona al pistacchio di Bronte, selezioniamo le migliori materie
            prime per realizzare dolci speciali
          </Text>
        </div>
        <StyledBannersColumnsContainer>
          <StyledBannersColumn>
            <BannerItem image={{ src: prodotti, alt: "prodotti" }} />
            <div className="borders">
              <Title color="dark">I NOSTRI PRODOTTI</Title>
              <Text color="dark">
                Biscotti, confetture, tavolette di cioccolata, nettari di
                frutta, tisane e tanto altro ancora: selezioniamo i migliori
                prodotti che potete acquistare da noi per regalare o regalarvi
                un momento speciale
              </Text>
            </div>
          </StyledBannersColumn>
          <StyledBannersColumn>
            <BannerItem image={{ src: spazio, alt: "spazio" }} />
            <div className="borders">
              <Title color="dark">IL NOSTRO SPAZIO</Title>
              <Text color="dark">
                Al di là del nostro coloratissimo bancone, alcuni tavoli sono a
                disposizione dei nostri clienti per la consumazione dei nostri
                dolci, accompagnati da caffè americano, tè e tisane e succhi di
                frutta artigianali
              </Text>
            </div>
          </StyledBannersColumn>
        </StyledBannersColumnsContainer>
      </StyledBannersContainer>
    </Container>
  );
};

Banners.propTypes = {};

Banners.defaultProps = {};

export default Banners;
