import styled, { css } from "styled-components";

const getIsLoaded = (props) => {
  if (props.isLoaded) {
    return css`
      opacity: 0;
      transform: translateY(-100%);
    `;
  }

  return css`
    opacity: 1;
    transform: translateY(0%);
  `;
};

const StyledPresentationBg = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 10;
  background-color: ${(props) => props.theme.colors.whiteBg};
  transition: all 0.2s ease-in 2s;
  ${(props) => getIsLoaded(props)};
`;

const StyledPresentation = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;

  svg {
    width: 150px;
  }

  .spinner {
    width: 40px;
    height: 40px;

    position: relative;
    margin: 20px auto;
  }

  .double-bounce1,
  .double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #ec8e8c;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;

    -webkit-animation: sk-bounce 2s infinite ease-in-out;
    animation: sk-bounce 2s infinite ease-in-out;
  }

  .double-bounce2 {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }

  @-webkit-keyframes sk-bounce {
    0%,
    100% {
      -webkit-transform: scale(0);
    }
    50% {
      -webkit-transform: scale(1);
    }
  }

  @keyframes sk-bounce {
    0%,
    100% {
      transform: scale(0);
      -webkit-transform: scale(0);
    }
    50% {
      transform: scale(1);
      -webkit-transform: scale(1);
    }
  }
`;

export { StyledPresentation, StyledPresentationBg };
