import React from "react";
import Feed from "react-instagram-authless-feed";
import { Container, Title, Text, Button } from "../../components";

import {
  StyledInstagramInfosContainer,
  StyledInstagramInfo,
} from "./StyledInstagramFeed";

const InstagramFeed = () => {
  return (
    <Container className="mobileContainer">
      <StyledInstagramInfosContainer>
        <StyledInstagramInfo>
          <Title style={{ paddingBottom: "0px" }}>Instagram</Title>
          <Text>@charlotte_pasticceria</Text>
        </StyledInstagramInfo>
        <StyledInstagramInfo>
          <Button
            tag={"a"}
            href={"https://www.instagram.com/charlotte_pasticceria/?hl=it"}
            target="_blank"
            rel="noopener noreferrer"
          >
            seguici su instagram
          </Button>
        </StyledInstagramInfo>
      </StyledInstagramInfosContainer>
      <Feed
        userName="charlotte_pasticceria"
        className="Feed"
        classNameLoading="feedLoading"
      />
    </Container>
  );
};

InstagramFeed.propTypes = {};

InstagramFeed.defaultProps = {};

export default InstagramFeed;
