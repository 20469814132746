import React, { useState, useEffect } from "react";
import {
  Header,
  ImageSlider,
  Footer,
  Container,
  Title,
  Text,
  Button,
  Services,
  InstagramFeed,
  Banners,
  AboutUs,
  Contact,
  Presentation,
} from "../../components";
import pasticceria_1 from "../../assets/mainSlider/pasticceria_1.jpg";
import pasticceria_2 from "../../assets/mainSlider/pasticceria_2.jpg";
import pasticceria_3 from "../../assets/mainSlider/pasticceria_3.jpg";
import pasticceria_4 from "../../assets/mainSlider/pasticceria_4.jpg";
import pasticceria_5 from "../../assets/mainSlider/pasticceria_5.jpg";
import pasticceria_6 from "../../assets/mainSlider/pasticceria_6.jpg";
import pasticceria_7 from "../../assets/mainSlider/pasticceria_7.jpg";
import pasticceria_8 from "../../assets/mainSlider/pasticceria_8.jpg";

const mainSliderImages = [
  { img: pasticceria_1, alt: "pasticceria_1" },
  { img: pasticceria_2, alt: "pasticceria_2" },
  { img: pasticceria_3, alt: "pasticceria_3" },
  { img: pasticceria_4, alt: "pasticceria_4" },
  { img: pasticceria_5, alt: "pasticceria_5" },
  { img: pasticceria_6, alt: "pasticceria_6" },
  { img: pasticceria_7, alt: "pasticceria_7" },
  { img: pasticceria_8, alt: "pasticceria_8" },
];

const AppContainer = () => {
  const [mainSliderLoaded, setMainSliderLoaded] = useState(false);
  const [aboutUsSliderLoaded, setAboutUsSliderLoaded] = useState(false);
  const [isImagesLoaded, setIsImagesLoaded] = useState(false);

  const isMainSliderLoaded = (event) => {
    setMainSliderLoaded(event);
  };

  const isAboutUsSliderLoaded = (event) => {
    setAboutUsSliderLoaded(event);
  };

  useEffect(() => {
    if (mainSliderLoaded && aboutUsSliderLoaded) {
      setIsImagesLoaded(true);
    }
  }, [mainSliderLoaded, aboutUsSliderLoaded]);

  return (
    <React.Fragment>
      <Presentation isLoaded={isImagesLoaded} />
      <Header />
      <ImageSlider
        images={mainSliderImages}
        bgColor="whiteBg"
        sliderLoaded={isMainSliderLoaded}
        className="mobileContainer"
      />
      <Container>
        <Title>
          Charlotte <br />
          È UNA PICCOLA PASTICCERIA ARTIGIANALE <br />
          DI ISPIRAZIONE FRANCESE
        </Title>
        <Text color="dark" style={{ marginTop: "20px" }}>
          <strong>Charlotte Pasticceria</strong> è una piccola boutique del
          dolce.
          <br />
          Ci lasciamo ispirare dalla moderna pasticceria francese,
          <br />
          dal nostro estro e dalle stagioni, per creare dolci personali e
          speciali.
          <br />
          Passa a trovarci per scoprire tutte le nostre creazioni!
        </Text>
        <Button
          tag={"a"}
          href="https://www.google.com/maps/place/Via+Vercelli,+12,+00182+Roma+RM/@41.8816638,12.5142376,17z/data=!3m1!4b1!4m5!3m4!1s0x132f61edbb358933:0x520ca2d597a005ea!8m2!3d41.8816638!4d12.5164263"
          target="_blank"
          rel="noopener noreferrer"
          style={{ marginTop: "40px", marginBottom: "60px" }}
        >
          Vieni a trovarci
        </Button>
      </Container>
      <Services />
      <Banners />
      <AboutUs sliderLoaded={isAboutUsSliderLoaded} />
      <InstagramFeed />
      <Contact />
      <Footer />
    </React.Fragment>
  );
};

export default AppContainer;
