import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { motion, useAnimation } from "framer-motion";
import "intersection-observer";
import { useInView } from "react-intersection-observer";

const BannerItem = ({ image }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial={{ opacity: 0, y: 150 }}
      transition={{
        ease: "easeInOut",
        duration: 0.3,
        delay: 0.1,
        damping: 20,
      }}
      variants={{
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: 150 },
      }}
    >
      <img
        src={image.src}
        alt={image.alt}
        className="bannerMobile"
        style={{ maxHeight: "400px", objectFit: "cover" }}
      />
    </motion.div>
  );
};

BannerItem.propTypes = {
  image: PropTypes.object,
};

BannerItem.defaultProps = {
  image: { src: "", alt: "" },
};

export default BannerItem;
