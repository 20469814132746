import React from 'react';
import PropTypes from 'prop-types';
import { StyledTitle } from './StyledTitle';

const Title = ({ children, color, size, ...rest }) => {
  return (
    <StyledTitle color={color} size={size} {...rest}>
      {children}
    </StyledTitle>
  );
};

Title.propTypes = {
  color: PropTypes.oneOf(['light_pink', 'dark', 'gray', 'white', 'pink']),
  size: PropTypes.oneOf(['small', 'big']),
};

Title.defaultProps = {
  color: 'dark',
  size: 'big',
};

export default Title;
