import styled from "styled-components";

const StyledBannersContainer = styled.div`
  padding-top: 50px;
  padding-bottom: 30px;

  img {
    width: 100%;
    margin-bottom: 40px;
  }
`;

const StyledBannersColumnsContainer = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 80px;
`;

const StyledBannersColumn = styled.div`
  width: 45%;

  @media (max-width: 767px) {
    width: 100%;

    &:first-child {
      margin-bottom: 40px;
    }
  }

  img {
    width: 100%;
  }
`;

export { StyledBannersContainer, StyledBannersColumnsContainer, StyledBannersColumn };
