import styled, { css } from 'styled-components';

const colorStyle = props => {
  return css`
    color: ${props.theme.colors[props.color]}
  `;
};

const sizeStyle = props =>  {
  if (props.size === "small") {
    return css`
      font-size: 14px;
      font-weight: 500;
      line-height: 30px;
    `;
  }
  return css`
    font-size: 26px;
    line-height: 58px;

    @media (max-width: 767px) {
      font-size: 20px;
      line-height: 34px;
    }
  `;
}

const StyledTitle = styled.h3`
  ${props => sizeStyle(props)};
  ${props => colorStyle(props)};
  text-transform: uppercase;
  padding-bottom: 25px;
  font-family: GothamBold, sans-serif;
  letter-spacing: 6px;
`;

export {
  StyledTitle,
};

