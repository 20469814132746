import React from "react";
import { Container, Title, Text, Button } from "../../components";
import { StyledServicesInfo, StyledColumnContainer } from "./StyledServices";
import ServiceItem from "./ServiceItem";

import macarons from "../../assets/mix/macarons.jpg";
// import monoporzioni from "../../assets/mix/monoporzioni.jpg";
import monoporzioni_2 from "../../assets/mix/monoporzioni_2.jpg";
import torte from "../../assets/mix/torte.jpg";
// import biscotti from "../../assets/mix/biscotti.jpg";
import biscotti_2 from "../../assets/mix/biscotti_2.jpg";

import panelLeft from "../../assets/sfondi/penneli-1.png";
import panelRight from "../../assets/sfondi/penneli-2.png";

const Services = () => {
  return (
    <Container
      bgColor="pink"
      style={{
        marginTop: "20px",
        maxWidth: "100%",
        paddingBottom: "50px",
        overflow: "hidden",
      }}
    >
      <img src={panelLeft} alt="paannello" className="panel-left noPhone" />
      <img src={panelRight} alt="paannello" className="panel-right noPhone" />

      <img src={panelLeft} alt="paannello" className="panel-left-bottom noPhone" />
      <img src={panelRight} alt="paannello" className="panel-right-bottom noPhone" />

      <div className="index">
        <StyledServicesInfo>
          <Title color="white">LA NOSTRA PASTICCERIA</Title>
          <Text color="white" weight="bold">
          Realizziamo dolci artigianali al 100%,
          alcuni disponibili durante tutto l’anno, altri stagionali o in edizione limitata.
          </Text>
          <Text color="white" styles="italic">
            Passa a trovarci per scoprire tutte le nostre creazioni!
          </Text>
        </StyledServicesInfo>

        <StyledColumnContainer>
          <ServiceItem
            image={{ src: macarons, alt: "macarons" }}
            title="MACARONS"
            subTitle="PASTICCERIA"
            text="I famosi macarons francesi, sono disponibili da noi in 10 gusti fissi più uno stagionale"
          />
          <ServiceItem
            image={{ src: monoporzioni_2, alt: "monoporzioni" }}
            title="MONOPORZIONI"
            subTitle="PASTICCERIA"
            text="Colori, consistenze, forme, gusti in formato monoporzione"
          />
          <ServiceItem
            image={{ src: torte, alt: "torte" }}
            title="TORTE"
            subTitle="PASTICCERIA"
            text="Le nostre monoporzioni più amate disponibili in formato torta, anche su prenotazione"
          />
          <ServiceItem
            image={{ src: biscotti_2, alt: "biscotti" }}
            title="BISCOTTI"
            subTitle="PASTICCERIA"
            text="Caramello scioglievole, lamponi freschi, pralinato di Nocciola...i nostri non sono semplici cookies!"
          />
        </StyledColumnContainer>

        <Text color="white" style={{ fontSize: "16px", letterSpacing: "5px" }}>
          PER SAPERNE DI PIÙ
        </Text>
        <Button
          tag={"a"}
          href={"tel:+39 0625399727"}
          style={{ marginTop: "10px" }}
        >
          Chiamaci
        </Button>
      </div>
    </Container>
  );
};

Services.propTypes = {};

Services.defaultProps = {};

export default Services;
