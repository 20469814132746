import styled, { css } from "styled-components";

const colorStyle = (props) => {
  return css`
    color: ${props.theme.colors[props.color]};
  `;
};

const getStyles = (props) => {
  if (props.styles === "italic") {
    return css`
      font-style: italic;
      font-weight: 300;
    `;
  }

  return css`
    font-style: normal;
    font-weight: 400;
  `;
};

const getWeight = (props) => {
  if (props.weight === "bold") {
    return css`
      font-weight: 700;
    `;
  }
  if (props.weight === "normal") {
    return css`
      font-weight: 400;
    `;
  }
  if (props.weight === "light") {
    return css`
      font-weight: 300;
    `;
  }
};

const StyledText = styled.p`
  font-size: 18px;
  ${(props) => colorStyle(props)};
  ${(props) => getStyles(props)};
  ${(props) => getWeight(props)};
  line-height: 39px;
  letter-spacing: 1px;
  margin-top: 0;
  margin-bottom: 6px;
  font-family: "Roboto", sans-serif;

  @media (max-width: 767px) {
    font-size: 15px;
    line-height: 28px;
  }

  strong {
    font-weight: 700;
  }
`;

export { StyledText };
