import styled from "styled-components";

const StyledInstagramInfosContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 30px;
  padding-top: 40px;

  @media (max-width: 767px) {
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const StyledInstagramInfo = styled.div`
  position: relative;

  @media (max-width: 767px) {
    &:first-child {
      margin-bottom: 20px;
    }
  }
`;

export { StyledInstagramInfosContainer, StyledInstagramInfo };
