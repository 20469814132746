import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Container } from "../";
import { StyledSliderContainer, StyledSliderItem } from "./StyledSlider";

const ImageSlider = ({ images, bgColor, sliderLoaded, ...rest }) => {
  
  const [loaded, setLoaded] = useState(false);
  const imgRef = useRef();

  useEffect(() => {
    if (imgRef.current && imgRef.current.complete) {
      setLoaded(true);
    }
  }, [setLoaded, imgRef]);

  useEffect(() => {
    if (loaded) {
      sliderLoaded(true);
    }
  }, [loaded, sliderLoaded]);

  const settings = {
    dots: true,
    autoplay: true,
    autoplaySpeed: 4000,
    infinite: true,
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: true,
    swipe: true,
    swipeToSlide: true,
    arrows: false,
  };

  return (
    <Container bgColor={bgColor} {...rest}>
      <StyledSliderContainer>
        <Slider {...settings}>
          {images.map((item, index) => {
            return (
              <StyledSliderItem key={index}>
                <img
                  src={item.img}
                  alt={item.alt}
                  ref={imgRef}
                  onLoad={() => setLoaded(true)}
                />
              </StyledSliderItem>
            );
          })}
        </Slider>
      </StyledSliderContainer>
    </Container>
  );
};

ImageSlider.propTypes = {
  images: PropTypes.array,
  bgColor: PropTypes.string,
  sliderLoaded: PropTypes.func,
};

ImageSlider.defaultProps = {
  images: [],
};

export default ImageSlider;
