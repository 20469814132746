import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { motion, useAnimation } from "framer-motion";
import "intersection-observer";
import { useInView } from "react-intersection-observer";
import { Title, Text } from "../../components";
import { StyledColumn } from "./StyledServices";

const ServiceItem = ({ image, title, subTitle, text }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <StyledColumn>
      <motion.div
        ref={ref}
        animate={controls}
        initial={{ opacity: 0, y: 150 }}
        transition={{
          ease: "easeInOut",
          duration: 0.3,
          delay: 0.1,
          damping: 20,
        }}
        variants={{
          visible: { opacity: 1, y: 0 },
          hidden: { opacity: 0, y: 150 },
        }}
      >
        <img
          src={image.src}
          alt={image.alt}
          style={{ maxHeight: "550px", objectFit: "cover" }}
        />
      </motion.div>
      <Text
        color="white"
        weight="light"
        style={{ letterSpacing: "5px", fontSize: "16px" }}
      >
        {subTitle}
      </Text>
      <Title color="white" style={{ marginTop: "20px" }}>
        {title}
      </Title>
      <Text color="white">{text}</Text>
    </StyledColumn>
  );
};

ServiceItem.propTypes = {
  image: PropTypes.object,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  text: PropTypes.string,
};

ServiceItem.defaultProps = {
  image: { src: "", alt: "" },
};

export default ServiceItem;
